import {
  ThemeFontWeights,
  ThemeTextVariant,
  ThemeTextVariants,
  ThemeTypography,
  TypographyFont,
  TypographyFonts,
} from "@package/common/features/themes"
import { ThemeVariables } from "./types"

const fontWeightVariables = (weights: ThemeFontWeights) => ({
  "--text-extraLight-w": weights.extraLight,
  "--text-light-w": weights.light,
  "--text-regular-w": weights.regular,
  "--text-medium-w": weights.medium,
  "--text-semiBold-w": weights.semiBold,
  "--text-bold-w": weights.bold,
  "--text-extraBold-w": weights.extraBold,
})

const themeTextVariant = (
  name: string,
  variant: ThemeTextVariant
): ThemeVariables => ({
  [`--text-${name}-s`]: variant.size,
  [`--text-${name}-h`]: variant.height,
  [`--text-${name}-w`]: variant.weight,
  [`--text-${name}-st`]: variant.style ?? "normal",
  [`--text-${name}-f`]: variant.font ?? "inherit", // family
})

const typographyVariantVariables = (
  variants: ThemeTextVariants
): ThemeVariables => ({
  ...Object.entries(variants).reduce(
    (obj, [key, value]) => ({
      ...obj,
      ...themeTextVariant(key, value),
    }),
    {}
  ),
})

const fontFamilyVariables = (
  name: string,
  font: TypographyFont
): ThemeVariables => ({
  [`--font-family-${name}`]: font.family,
})

const fontFamiliesVariables = (fonts: TypographyFonts): ThemeVariables => ({
  ...fontFamilyVariables("default", fonts.default),
  ...fontFamilyVariables("secondary", fonts.secondary ?? fonts.default),
})

export const buildThemeTypographyVariables = (
  typography: ThemeTypography
): ThemeVariables => ({
  ...fontWeightVariables(typography.weights),
  ...typographyVariantVariables(typography.variants),
  ...fontFamiliesVariables(typography.fonts),
})
