import React from "react"
import ErrorMessage from "../ErrorMessage"

export class ErrorsWrapper extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({
      ...this.state,
      error,
      errorInfo,
    })
  }

  handleClick = () => {
    this.setState({ hasError: false, error: null })
  }
  render() {
    if ((this.state as any).hasError) {
      // render fallback UI
      return (
        <ErrorMessage
          onRetry={this.handleClick}
          errorDetails={JSON.stringify(this.state)}
        />
      )
    }

    return (this.props as any).children
  }
}
