import { rootContentActions } from "@modules/contents/state"
import { useAppDispatch, useAppSelector } from "@modules/store/redux"
import React from "react"

const RootDataInitializer = () => {
  const dispatch = useAppDispatch()
  const { uid } = useAppSelector((state) => state.auth)

  React.useEffect(() => {
    if (uid) {
      dispatch(rootContentActions.fetchDataTrigger())
    }
  }, [uid])

  return <></>
}

export default RootDataInitializer
