import { useAppSelector } from "@modules/store/redux"
import { ThemeMode } from "@common/frontend/modules/theme/types"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { uiActions } from "../state"

export const useUiSettings = () => {
  const dispatch = useDispatch()
  const { mode, sidebar } = useAppSelector((state) => state.ui)

  const setThemeMode = useCallback(
    (mode: ThemeMode) => dispatch(uiActions.setThemeMode(mode)),
    []
  )

  const setSidebarCollapsed = useCallback(
    (collapsed: boolean) => dispatch(uiActions.setSidebarCollapsed(collapsed)),
    []
  )

  return {
    themeMode: mode,
    sidebarCollapsed: sidebar.collapsed,
    setThemeMode,
    setSidebarCollapsed,
  }
}
