export class InvalidColorError extends Error {}

export type RgbColor = {
  r: number
  g: number
  b: number
}

export type RgbaColor = {
  r: number
  g: number
  b: number
  a: number
}

export type HslColor = {
  h: number
  s: number
  l: number
}

export type HslaColor = {
  h: number
  s: number
  l: number
  a: number
}

export type HexColor = {
  rHex: string
  gHex: string
  bHex: string
}

export type HexaColor = {
  rHex: string
  gHex: string
  bHex: string
  aHex: string
}
