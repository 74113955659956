import {
  createUserProfileSlice,
  userProfileRoutines,
} from "@package/common/frontend/features/profile/state/state"

const profileSlice = createUserProfileSlice({})

export default profileSlice.reducer

export const profileStateName = profileSlice.name
export const profileActions = {
  ...userProfileRoutines,
  ...profileSlice.actions,
}
