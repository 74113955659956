import { AppTheme, TypographyFonts } from "@package/common/features/themes"
import React from "react"
import { HeadWrapper } from "../../../abstractions/components"

export interface ThemeFontsImportProps {
  Head: HeadWrapper
  theme: AppTheme
}

const getFontsToImport = (font: TypographyFonts) =>
  Object.values(font).filter((x) => x?.url)

export const ThemeFontsImport = ({ Head, theme }: ThemeFontsImportProps) => {
  return (
    <Head>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      {getFontsToImport(theme.typography.fonts).map((x) => (
        <link key={x.family} href={x.url} rel="stylesheet" />
      ))}
    </Head>
  )
}
