import classNames from "classnames"
import React from "react"
import { getTextVariable } from "./functions"
import { TextColor as TextColorType, TypographyProps } from "./types"
import styles from "./Typography.module.css"

const Typography = ({
  component,
  children,
  className,
  font,
  textTransform,
  textAlign,
  underline,
  truncate,
  color,
  colorValue,
  variant,
  lineHeight,
  lineHeightValue,
  fontSize,
  fontSizeValue,
  weight,
  weightValue,
  style,
  innerRef,
  ...props
}: TypographyProps) => {
  const Tag = component ?? "p"
  const weights = getTextVariable(
    weight ?? variant,
    weightValue,
    "font-weight",
    {
      valSuffix: "w",
      valPrefix: "text",
    }
  )
  const fontSizes = getTextVariable(
    fontSize ?? variant,
    fontSizeValue,
    "font-size",
    {
      valSuffix: "s",
      valPrefix: "text",
    }
  )
  const heights = getTextVariable(
    lineHeight ?? variant,
    lineHeightValue,
    "line-height",
    {
      valSuffix: "h",
      valPrefix: "text",
    }
  )
  const aligns = getTextVariable(null, textAlign, "text-align")
  const transforms = getTextVariable(null, textTransform, "text-transform")
  const colors = getTextVariable(color ?? variant, colorValue, "text-color", {
    valPrefix: "color-text",
  })
  const families = getTextVariable(font, null, "font-family", {
    valPrefix: "font-family",
  })
  return (
    //@ts-ignore
    <Tag
      //@ts-ignore
      className={classNames(
        styles.root,
        truncate && styles.truncate,
        className
      )}
      style={{
        ...weights,
        ...fontSizes,
        ...heights,
        ...aligns,
        ...transforms,
        ...families,
        ...colors,
        ...style,
      }}
      {...props}
      ref={innerRef}
    >
      {children}
    </Tag>
  )
}

Typography.defaultProps = {
  variant: "body1",
}

export default Typography
export type TextColor = TextColorType
