import { IStore } from "@package/common/abstractions/storage"
import {
  clearObjects,
  readObject,
  removeObject,
  writeObject,
} from "@utils/storage"

export class AppLocalStorage implements IStore {
  async save<T>(key: string, value: T): Promise<void> {
    writeObject(key, value)
  }
  async get<T>(key: string): Promise<T | undefined> {
    return readObject<T>(key)
  }
  async delete(key: string): Promise<void> {
    removeObject(key)
  }
  async clear(): Promise<void> {
    clearObjects()
  }
}

export const appStateStorage = new AppLocalStorage()
