import { useAppInit } from "@modules/initialization/hooks/useAppInit"
import React from "react"

interface Props {
  children: any
}

const AppInitProvider = ({ children }: Props) => {
  const { isInitialized } = useAppInit()
  if (!isInitialized) {
    return <></>
  }

  return <>{children}</>
}

export default AppInitProvider
