import { configureStore, Action } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import logger from "redux-logger"
import { ThunkAction } from "redux-thunk"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { rootReducer, RootState } from "./rootReducer"
import { sagaContext } from "./sagaContext"
import { sagas } from "./rootSagas"
import { CommonState } from "@package/common/frontend/state/types"

const sagaMiddleware = createSagaMiddleware({
  context: sagaContext,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false }),
    logger,
    sagaMiddleware,
  ],
  devTools: true, // process.env.NODE_ENV !== "production",
})

sagas.forEach((saga) => sagaMiddleware.run(saga))

export const rootSelector = (state: RootState) => state

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useCommonSelector: TypedUseSelectorHook<CommonState> = useSelector

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store
