import {
  collection,
  doc,
  addDoc,
  setDoc,
  getDoc,
  Firestore,
  onSnapshot,
  QueryDocumentSnapshot,
  CollectionReference,
  Query,
  serverTimestamp,
  DocumentData,
  getDocs,
  query,
  orderBy,
  startAfter,
  limit,
  writeBatch,
  WriteBatch,
  startAt,
  QuerySnapshot,
} from "firebase/firestore"

import { Entity } from "./types"

export const setServerTimestamp = () => {
  return serverTimestamp()
}

export interface FetchSubCollectionPagedInput {
  parentId: string
  subCollection: string
  cursor: string
  sortProperty: string
  sortOrder: "asc" | "desc"
  pageSize: number
}

export interface FetchSubCollectionElementsInput {
  parentId: string
  subCollection: string
  startElementId: string
  sortProperty: string
  sortOrder: "asc" | "desc"
}

export interface Firebase {
  firestore: Firestore
}

export class FirestoreControllerBase<T> {
  private _collection: CollectionReference<Entity<T>> | undefined

  constructor(
    private readonly firebase: Firebase,
    private readonly collectionName: string
  ) {}

  async initBatch() {
    return writeBatch(this.firebase.firestore)
  }

  protected getCollection(): CollectionReference<Entity<T>> {
    if (!this._collection) {
      this._collection = this.initializeCollection() as any
    }
    return this._collection as any
  }

  private initializeCollection() {
    return collection(
      this.firebase.firestore,
      this.collectionName
    ).withConverter(this.getConverter() as any)
  }

  private getConverter() {
    return {
      toFirestore: (item: Entity<T>) => {
        return item.data
      },
      fromFirestore: (snap: QueryDocumentSnapshot) =>
        ({
          id: snap.id,
          data: snap.data(),
        } as Entity<T>),
    }
  }
}

export interface CreateChildDocInput<TChild> {
  parentId: string
  subCollectionName: string
  childId: string
  data: TChild
}

export class FirestoreController<T> extends FirestoreControllerBase<T> {
  constructor(firebase: Firebase, collectionName: string) {
    super(firebase, collectionName)
  }

  async getOrCreateDocument(input: Entity<T>) {
    const docItem = doc(this.getCollection(), input.id)
    if ((await getDoc(docItem)).exists()) {
      return docItem
    }

    await setDoc(docItem, input)
    return docItem
  }

  protected async docRef(id: string) {
    return doc(this.getCollection(), id)
  }

  protected async subCollectionRef(parentId: string, subCollection: string) {
    return collection(doc(this.getCollection(), parentId), subCollection)
  }

  protected async get(id: string) {
    return (await getDoc(doc(this.getCollection(), id))).data()
  }

  protected async getAll() {
    const querySnapshot = await getDocs(this.getCollection())
    return querySnapshot.docs.map((doc) => doc.data())
  }

  protected async update(input: Entity<Partial<T>>, batch?: WriteBatch) {
    const document = doc(this.getCollection(), input.id)
    if (batch != null) {
      batch.set(document, input, {
        merge: true,
      })
    } else {
      await setDoc(document, input, {
        merge: true,
      })
    }
  }

  protected async getOrCreate(input: Entity<T>): Promise<Entity<T>> {
    const document = doc(this.getCollection(), input.id)
    const docItem = await getDoc(document)
    if (docItem.exists()) {
      return docItem.data() as Entity<T>
    }

    await setDoc(document, input)
    return (await getDoc(document)).data() as Entity<T>
  }

  protected async createChildDocument<TChild>(
    input: CreateChildDocInput<TChild>,
    batch?: WriteBatch
  ) {
    const { parentId, subCollectionName, childId, data } = input
    const docItem = doc(
      collection(doc(this.getCollection(), parentId), subCollectionName),
      childId
    )
    if (batch != null) {
      batch.set(docItem, data as any)
    } else {
      return await setDoc(docItem, data as any)
    }
  }

  protected subscribeCollection(callback: (items: Entity<T>[]) => void) {
    const unsubscribe = onSnapshot(this.getCollection(), (snap) => {
      callback(snap.docs.map((x) => x.data()))
    })

    return () => unsubscribe()
  }

  protected subscribeDocument(
    id: string,
    callback: (item: Entity<T> | undefined) => void
  ) {
    const unsubscribe = onSnapshot(doc(this.getCollection(), id), (snap) => {
      console.log("doc callback", id, snap)
      callback(snap.data())
    })

    return () => unsubscribe()
  }

  protected subscribeQuery(
    queryBuilder: (
      collection: CollectionReference<Entity<T>>
    ) => Query<Entity<T>>,
    callback: (items: Entity<T>[]) => void
  ) {
    return this.subscribeQueryRaw(queryBuilder, (snap) =>
      callback(snap.docs.map((x) => x.data()))
    )
  }

  protected subscribeQueryRaw(
    queryBuilder: (
      collection: CollectionReference<Entity<T>>
    ) => Query<Entity<T>>,
    callback: (snap: QuerySnapshot<Entity<T>>) => void
  ) {
    const unsubscribe = onSnapshot(
      queryBuilder(this.getCollection()),
      (snap) => {
        callback(snap)
      }
    )

    return () => unsubscribe()
  }

  protected subscribeSubCollection<T>(
    parentId: string,
    subCollectionName: string,
    callback: (items: T[]) => void
  ) {
    const unsubscribe = onSnapshot(
      collection(doc(this.getCollection(), parentId), subCollectionName),
      (snap) => {
        callback(snap.docs.map((x) => x.data() as T))
      }
    )

    return () => unsubscribe()
  }

  protected subscribeSubCollectionQueryRaw(
    parentId: string,
    subCollectionName: string,
    queryBuilder: (
      collection: CollectionReference<DocumentData>
    ) => Query<DocumentData>,
    callback: (items: DocumentData[]) => void
  ) {
    const unsubscribe = onSnapshot(
      queryBuilder(
        collection(doc(this.getCollection(), parentId), subCollectionName)
      ),
      (snap) => {
        //todo: check if we want only online updates
        //snap.metadata.hasPendingWrites
        callback(snap.docs)
      }
    )

    return () => unsubscribe()
  }

  protected subscribeSubCollectionQuery(
    parentId: string,
    subCollectionName: string,
    queryBuilder: (
      collection: CollectionReference<DocumentData>
    ) => Query<DocumentData>,
    callback: (items: DocumentData[]) => void
  ) {
    return this.subscribeSubCollectionQueryRaw(
      parentId,
      subCollectionName,
      queryBuilder,
      (data) => {
        callback(data.map((x) => x.data()))
      }
    )
  }

  protected async fetchSubCollectionPaged(input: FetchSubCollectionPagedInput) {
    const {
      parentId,
      subCollection,
      cursor,
      sortProperty,
      sortOrder,
      pageSize,
    } = input

    const cursorDoc = await getDoc(
      doc(
        collection(doc(this.getCollection(), parentId), subCollection),
        cursor
      )
    )
    if (!cursorDoc) {
      throw new Error(`Cursor doc ${cursor} not found`)
    }
    return await getDocs(
      query(
        collection(doc(this.getCollection(), parentId), subCollection),
        orderBy(sortProperty, sortOrder),
        startAfter(cursorDoc),
        limit(pageSize)
      )
    )
  }

  protected async fetchSubCollectionElements(
    input: FetchSubCollectionElementsInput
  ) {
    const { parentId, subCollection, startElementId, sortProperty, sortOrder } =
      input

    const cursorDoc = await getDoc(
      doc(
        collection(doc(this.getCollection(), parentId), subCollection),
        startElementId
      )
    )
    if (!cursorDoc) {
      throw new Error(`Cursor doc ${startElementId} not found`)
    }
    return await getDocs(
      query(
        collection(doc(this.getCollection(), parentId), subCollection),
        orderBy(sortProperty, sortOrder),
        startAt(cursorDoc)
      )
    )
  }
}
