import { AppTheme } from "@package/common/features/themes"

export const ThemeVariables: AppTheme = {
  brand: {
    metadata: {
      name: "Modus",
    },
    images: {
      icon: {
        primary: {
          src: "/img/modus_logo_icon.jpg",
        },
        negative: {
          src: "",
        },
      },
      logo: {
        primary: {
          src: "https://cdn.sanity.io/images/srnt2ltl/production/f47000ce402d5806f4a7837aaee225a89eaa1c3c-1130x328.svg?w=250",
        },
        negative: {
          src: "/media/logos/logo-1-dark.svg",
        },
      },
    },
  },
  typography: {
    fonts: {
      default: {
        family: "Manrope",
        url: "https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;500;600;700;800&display=swap",
      },
      secondary: {
        family: "Quattrocento",
        url: "https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;500;600&display=swap",
      },
    },
    weights: {
      extraLight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
    variants: {
      extra: {
        size: "44px",
        height: "56px",
        weight: 500,
      },
      h1: {
        size: "44px",
        height: "56px",
        weight: 500,
      },
      h2: {
        size: "38px",
        height: "54px",
        weight: 500,
      },
      h3: {
        size: "30px",
        height: "42px",
        weight: 500,
      },
      h4: {
        size: "26px",
        height: "36px",
        weight: 500,
      },
      h5: {
        size: "23px",
        height: "30px",
        weight: 500,
      },
      h6: {
        size: "20px",
        height: "28px",
        weight: 500,
      },
      subtitle1: {
        size: "18px",
        height: "24px",
        weight: 500,
      },
      subtitle2: {
        size: "16px",
        height: "20px",
        weight: 500,
      },
      body1: {
        size: "14px",
        height: "16px",
        weight: 400,
      },
      body2: {
        size: "12px",
        height: "14px",
        weight: 400,
      },
      caption1: {
        size: "14px",
        height: "16px",
        weight: 400,
      },
      caption2: {
        size: "12px",
        height: "14px",
        weight: 400,
      },
      label1: {
        size: "14px",
        height: "16px",
        weight: 600,
      },
      label2: {
        size: "12px",
        height: "14px",
        weight: 600,
      },
    },
  },
  palette: {
    light: {
      primary: {
        default: "#009ef7",
        contrast: "#ffffff",
        light: "#f5f8fa",
      },
      secondary: {
        default: "#122636",
        contrast: "#ffffff",
        light: "#f5f8fa",
      },
      info: {
        default: "#7239ea",
        contrast: "#ffffff",
        light: "#f8f5ff",
      },
      success: {
        default: "#50cd89",
        contrast: "#ffffff",
        light: "#e8fff3",
      },
      error: {
        default: "#f1416c",
        contrast: "#ffffff",
        light: "#fff5f8",
      },
      warning: {
        default: "#ffc700",
        contrast: "#ffffff",
        light: "#fff8dd",
      },
      grey: {
        default: "#292A2C",
        medium: "#b5b5c3",
        light: "rgba(41, 42, 44, 0.25)",
        extraLight: "rgba(41, 42, 44, 0.25)",
        dark: "#212121",
        extraDark: "#1C1C1C",
      },
      background: {
        default: "#f5f8fa",
      },
      paper: {
        default: "#ffffff",
        contrast: "#000000",
      },
      negative: {
        default: "#000000",
        contrast: "#ffffff",
      },
      border: {
        default: "#e6e6e6",
      },
      sidebar: {
        background: "#1e1e2d",
        content: "#ffffff",
      },
      text: {
        default: "#464E5F",
        // default2: "#181c32",
        caption: "#b5b5c3",
      },
    },
    dark: {
      primary: {
        default: "#009ef7",
        contrast: "#000000",
        light: "#f5f8fa",
      },
      secondary: {
        default: "#122636",
        contrast: "#000000",
        light: "#f5f8fa",
      },
      info: {
        default: "#7239ea",
        contrast: "#000000",
        light: "#f8f5ff",
      },
      success: {
        default: "#50cd89",
        contrast: "#000000",
        light: "#e8fff3",
      },
      error: {
        default: "#f1416c",
        contrast: "#000000",
        light: "#fff5f8",
      },
      warning: {
        default: "#ffc700",
        contrast: "#000000",
        light: "#fff8dd",
      },
      grey: {
        default: "#292A2C",
        medium: "#b5b5c3",
        light: "rgba(41, 42, 44, 0.25)",
        extraLight: "rgba(41, 42, 44, 0.25)",
        dark: "#212121",
        extraDark: "#1C1C1C",
      },
      background: {
        default: "rgb(51, 51, 51)",
      },
      paper: {
        default: "#000000",
        contrast: "#ffffff",
      },
      negative: {
        default: "#ffffff",
        contrast: "#000000",
      },
      border: {
        default: "#1F1F1F",
      },
      sidebar: {
        background: "#1e1e2d",
        content: "#000000",
      },
      text: {
        default: "#ffffff",
        caption: "#4D4D5F",
      },
    },
  },
}
