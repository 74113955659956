import { useAppDispatch, useAppSelector } from "@modules/store/redux"
import { useEffect } from "react"
import { appActions } from "../state"

export const useAppInit = () => {
  const dispatch = useAppDispatch()
  const { isInitialized } = useAppSelector((state) => state.app)
  useEffect(() => {
    dispatch(appActions.load.trigger())
  }, [])

  return {
    isInitialized,
  }
}
