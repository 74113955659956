import { hexToRgb, rgbaToHsla, rgbToHsl } from "./converters"
import { parseHexColor, parseRgbaColor, parseRgbColor } from "./parsers"
import { HslColor } from "./types"
import { isValidHexColor, isValidRgbaColor, isValidRgbColor } from "./utils"

export const convertToHsl = (color: string): HslColor => {
  if (isValidHexColor(color)) {
    return rgbToHsl(hexToRgb(parseHexColor(color)))
  }

  if (isValidRgbaColor(color)) {
    return rgbaToHsla(parseRgbaColor(color))
  }

  if (isValidRgbColor(color)) {
    return rgbToHsl(parseRgbColor(color))
  }

  throw new Error(`Unrecognized color: ${color}`)
}
