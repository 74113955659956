import { getSagaContext } from "@package/common/frontend/state/context"
import { ThemeMode } from "@common/frontend/modules/theme/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { call } from "typed-redux-saga"
import { UiStoreKeys } from "../storage"

export function* setThemeMode(action: PayloadAction<ThemeMode>) {
  const storage = yield* getSagaContext("store")
  yield* call(storage.save, UiStoreKeys.ThemeMode, action.payload)
}
