import ConfirmationModal from "@ui/organisms/ConfirmationModal"
import React from "react"
import { UserConfirmationDefinition } from "./types"

export interface ConfirmationModalAdapterProps {
  modal: UserConfirmationDefinition
  onCancel: () => void
  onConfirm: () => void
}

export const ConfirmationModalAdapter = ({
  modal,
  onCancel,
  onConfirm,
}: ConfirmationModalAdapterProps) => {
  return (
    <ConfirmationModal
      onClose={onCancel}
      title={modal.title}
      message={modal.message}
      open
      cancel={{
        label: modal.cancel.label,
        onClick: onCancel,
      }}
      confirm={{
        label: modal.confirm.label,
        onClick: onConfirm,
      }}
    />
  )
}
