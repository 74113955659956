import type { AppProps } from "next/app"

import "tailwindcss/tailwind.css"
import "styles/tailwind.css"
import "styles/globals.scss"

import React from "react"
import { appWithTranslation } from "next-i18next"
import { AppRootProviders } from "@root/providers"
import { StoreProvider } from "@modules/store"

function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <StoreProvider>
      <AppRootProviders>
        <Component {...pageProps} />
      </AppRootProviders>
    </StoreProvider>
  )
}

export default appWithTranslation(CustomApp)
