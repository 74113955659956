import {
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signOut,
  getIdToken,
  User,
} from "firebase/auth"
import Firebase from "."

export const getUserCustomAttributes = (user: User) => {
  const customAttributes = (user as any)?.reloadUserInfo?.customAttributes
  return customAttributes ? JSON.parse(customAttributes) : {}
}

export function getUid(this: Firebase) {
  return this.user?.uid
}

export function getFirebaseToken(this: Firebase) {
  return this.token
}

export async function login(this: Firebase, { email, password }: LoginInfo) {
  const userCredential = await signInWithEmailAndPassword(
    this.auth,
    email,
    password
  )
  this.token = await getIdToken(userCredential.user)
  this.user = userCredential.user
  this.tokenResult = await userCredential.user.getIdTokenResult()
}
export async function logout(this: Firebase) {
  this.user = null
  this.token = null
  this.tokenResult = null
  await signOut(this.auth)
}

export function getUser(this: Firebase) {
  return this.user
}

const TOKEN_REFRESH_INTERVAL = 1000 * 60 * 5

export function watchIsLoggedIn(
  this: Firebase,
  callback: (isLoggedIn: boolean) => void
) {
  console.log("Watch AUTH STATE started")
  setInterval(async () => {
    console.log("refresh token", new Date())
    const tokenResult = await this.user?.getIdTokenResult()
    if (tokenResult) {
      this.tokenResult = tokenResult
    }
    const token = await this.user?.getIdToken(true)
    if (token) {
      console.log("new token", token)
      this.token = token
    }
  }, TOKEN_REFRESH_INTERVAL)

  const unsub1 = onIdTokenChanged(
    this.auth,
    async (user) => {
      console.log("onIdTokenChanged", user)
      if (user) {
        this.tokenResult = await user.getIdTokenResult()
        this.token = await user.getIdToken()
        this.user = user
      }
      // callback(user != null && !user.isAnonymous)
    },
    (error) => console.error("onIdTokenChanged", error)
  )
  const unsub2 = onAuthStateChanged(
    this.auth,
    async (user) => {
      console.log("onAuthStateChanged", user)
      if (user) {
        this.tokenResult = await user.getIdTokenResult()
        this.token = await user.getIdToken()
        this.user = user
      }
      callback(user != null && !user.isAnonymous)
    },
    (error) => console.error("onAuthStateChanged", error)
  )
  return () => {
    unsub1()
    unsub2()
  }
}

export type LoginInfo = {
  email: string
  password: string
}
