import { ThemeMode } from "@common/frontend/modules/theme/types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UiInitializationData, UiState } from "./types"

const state = createSlice({
  name: "ui",
  initialState: {
    mode: "light",
    sidebar: {
      collapsed: false,
    },
  } as UiState,
  reducers: {
    initializeTrigger() {},
    initializeCompleted(state, action: PayloadAction<UiInitializationData>) {
      state.mode = action.payload.mode
      state.sidebar.collapsed = action.payload.sidebarCollapsed
    },
    setThemeMode(state, action: PayloadAction<ThemeMode>) {
      state.mode = action.payload
    },
    setSidebarCollapsed(state, action: PayloadAction<boolean>) {
      state.sidebar.collapsed = action.payload
    },
  },
})

export const uiActions = state.actions
export const uiReducer = state.reducer
export const uiName = state.name
