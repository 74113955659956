import {
  createNotificationsSliceContext,
  createNotificationsState,
  notificationsRoutines,
} from "@package/common/frontend/features/notifications/state"

export interface ExtraNotificationsState {}

export const notificationsSlice =
  createNotificationsState<ExtraNotificationsState>({})

const {
  name: notificationsName,
  actions: sliceActions,
  reducer: notificationsReducer,
} = notificationsSlice

const notificationsActions = {
  ...sliceActions,
  ...notificationsRoutines,
}
export const notificationsSliceContext =
  createNotificationsSliceContext(notificationsSlice)
export type NotificationsSliceContext = typeof notificationsSliceContext

export { notificationsName, notificationsReducer, notificationsActions }
