import { authName, authReducer } from "@modules/auth/redux"
import calendarsReducer, { calendarStateName } from "@features/calendar/state"
import { combineReducers } from "@reduxjs/toolkit"
import profileReducer, { profileStateName } from "@features/profile/state"
import messagingReducer, { messagingStateName } from "@features/messaging/state"
import { appReducer, appStateName } from "@modules/initialization/state"
import { rootContentsName, rootContentsReducer } from "@modules/contents/state"
import {
  performanceName,
  performanceReducer,
} from "@features/performance/state"
import { uiName, uiReducer } from "@modules/ui/state"
import { contactsSlice } from "@package/common/frontend/features/contacts/state"

export const rootReducer = combineReducers({
  [authName]: authReducer,
  [uiName]: uiReducer,
  [messagingStateName]: messagingReducer,
  [calendarStateName]: calendarsReducer,
  [profileStateName]: profileReducer,
  [appStateName]: appReducer,
  [rootContentsName]: rootContentsReducer,
  [performanceName]: performanceReducer,
  [contactsSlice.name]: contactsSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>
