import { normalizeChannelMessage } from "@package/common/database/messaging/channel/channel.converter"
import { ImChannelMessage } from "@package/common/database/messaging/channel/channel.entity"
import { decryptMessage } from "@package/common/features/messaging/common"
import { normalizeChatMessage } from "../../../../../database/messaging/chat/chat.converter"
import { ImDecodedChannelMessage, ImDecodedChatMessage } from "../types"

export const decodeAndNormalizeChannelMsg = (
  privateKey: string,
  message: ImChannelMessage
): ImDecodedChannelMessage => {
  const { m, s, otc, ...other } = normalizeChannelMessage(message)
  const decryptedMsg = decryptMessage({
    message: m,
    oneTimeCode: otc,
    subject: s,
    privateKey,
  })
  return {
    ...other,
    message: decryptedMsg.message,
    subject: decryptedMsg.subject,
  }
}

export const decodeAndNormalizeChatMsg = (
  privateKey: string,
  message: ImChannelMessage
): ImDecodedChatMessage => {
  const { m, s, otc, ...other } = normalizeChatMessage(message)
  const decryptedMsg = decryptMessage({
    message: m,
    oneTimeCode: otc,
    subject: s,
    privateKey,
  })
  return {
    ...other,
    message: decryptedMsg.message,
    subject: decryptedMsg.subject,
  }
}
