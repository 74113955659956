import LogRocket from "logrocket"
import React from "react"

export const useLogRocket = () =>
  React.useEffect(() => {
    if (process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID) {
      console.log("logrocket init")
      LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID)
    }
  }, [])
