import { AppTheme } from "@package/common/features/themes"
import { buildThemePaletteVariables } from "./palette"
import { buildThemeTypographyVariables } from "./typography"

export const buildThemeVariables = (theme: AppTheme) => {
  return {
    ...buildThemePaletteVariables(theme.palette),
    ...buildThemeTypographyVariables(theme.typography),
  }
}
