import { authRootSaga } from "@modules/auth/redux"
import { messagingRootSaga } from "@package/common/frontend/features/messaging/sagas"
import { calendarsRootSaga } from "@package/common/frontend/features/calendar/sagas"
import { userProfileRootSaga } from "@package/common/frontend/features/profile/sagas"
import { appRootSaga } from "@modules/initialization/state/sagas"
import { appContentsRootSaga } from "@modules/contents/sagas"
import { performancesRootSaga } from "@package/common/frontend/features/performance/sagas"
import { uiRootSaga } from "@modules/ui/sagas"

export const sagas = [
  appRootSaga,
  authRootSaga,
  messagingRootSaga,
  calendarsRootSaga,
  userProfileRootSaga,
  appContentsRootSaga,
  performancesRootSaga,
  uiRootSaga,
]
