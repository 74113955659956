import { useTranslations } from "@modules/translations"
import CustomButton from "@ui/atoms/CustomButton"
import Typography from "@ui/atoms/Typography"
import Modal from "@ui/structure/Modal"
import React from "react"

export interface ErrorMessageProps {
  onRetry: () => void
  errorDetails?: string
}

const ErrorMessage = ({ onRetry, errorDetails }: ErrorMessageProps) => {
  const { t } = useTranslations("common")
  const [showError, setShowError] = React.useState(false)
  return (
    <div className="grid justify-center place-content-center w-full h-full py-[10vh]">
      <Typography>{t("errors.genericError")}</Typography>
      <CustomButton color="primary" onClick={onRetry} className="mt-2">
        {t("errors.retry")}
      </CustomButton>

      <div className="mt-8 text-center">
        <CustomButton color="error" onClick={() => setShowError(true)}>
          Dettagli errore
        </CustomButton>
        <Modal open={showError} onClose={() => setShowError(false)}>
          <div className="mt-4">{errorDetails}</div>
        </Modal>
      </div>
    </div>
  )
}

export default ErrorMessage
