import { getSagaContext } from "@package/common/frontend/state/context"
import { ThemeMode } from "@common/frontend/modules/theme/types"
import { call, put } from "typed-redux-saga"
import { uiActions } from "../state"
import { UiStoreKeys } from "../storage"

export function* initializeUi() {
  const storage = yield* getSagaContext("store")
  const mode = yield* call(storage.get, UiStoreKeys.ThemeMode)
  const sidebarCollapsed = yield* call(
    storage.get,
    UiStoreKeys.SidebarCollapsed
  )

  yield* put(
    uiActions.initializeCompleted({
      mode: mode as ThemeMode,
      sidebarCollapsed: sidebarCollapsed as boolean,
    })
  )
}
