import { buildUrl } from "@utils/url"

export const dashboardPagePath = () => "/dashboard"
export const coursesPagePath = () => "/courses"
export const loginPagePath = () => "/auth/login"
export const unauthorizedPagePath = () => "/unauthorized"
export const resetPasswordUrl = (email: string) =>
  buildUrl("/auth/forgot-password", {
    email,
  })
