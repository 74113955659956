import { SagaContext as BaseSagaContext } from "@package/common/frontend/state/context"
import { getContext } from "redux-saga/effects"
import { appStateStorage } from "./store"
import { api, firebase } from "@services/common"
import { calendarSliceContext } from "@features/calendar/state"
import { messagingSliceContext } from "@features/messaging/state"
import { notificationsSliceContext } from "@modules/notifications/state"
import { performanceSliceContext } from "@features/performance/state"
import { getStateContext } from "@package/common/frontend/state/context"

export const sagaStateContext = getStateContext({
  calendar: calendarSliceContext,
  messaging: messagingSliceContext,
  notifications: notificationsSliceContext,
  performance: performanceSliceContext,
})

export type SagaStateContext = typeof sagaStateContext

export interface SagaContext extends BaseSagaContext<SagaStateContext> {
  firebase: typeof firebase
}

export const sagaContext: SagaContext = {
  firebase,
  state: sagaStateContext,
  store: appStateStorage,
  api,
}

export function* getSagaContext<T extends keyof SagaContext>(
  key: T
): Generator<any, SagaContext[T], any> {
  return yield getContext(key)
}
