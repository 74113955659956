import { takeLatest } from "typed-redux-saga"
import { uiActions } from "../state"
import { initializeUi } from "./initialize"
import { setSidebarCollapsed } from "./setSidebarCollapsed"
import { setThemeMode } from "./setThemeMode"

export function* uiRootSaga() {
  yield* takeLatest(uiActions.initializeTrigger, initializeUi)
  yield* takeLatest(uiActions.setThemeMode, setThemeMode)
  yield* takeLatest(uiActions.setSidebarCollapsed, setSidebarCollapsed)
}
