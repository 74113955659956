import { getUserCustomAttributes } from "@services/firebase/auth"
import { User } from "firebase/auth"
import { UserData } from "./types"

export const extractUserData = (user: User): UserData => ({
  uid: user.uid,
  userType: getUserCustomAttributes(user)?.userType,
  brandGroupCode: getUserCustomAttributes(user)?.brandGroupCode,
  profile: {
    email: user.email ?? "",
    name: user.displayName ?? "",
  },
})
