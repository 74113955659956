import { createRoutine } from "redux-saga-routines"
import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "./types"

const name = "app"

const initialState: AppState = {
  isInitialized: false,
  isLoading: true,
}

const load = createRoutine("APP|LOAD")

const routines = {
  load,
}

const slice = createSlice({
  name,
  reducers: {
    setIsInitialized(state) {
      state.isInitialized = true
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(load.FULFILL, (state) => {
      state.isLoading = false
    })
  },
})

const { name: appStateName, actions: sliceActions, reducer: appReducer } = slice

const appActions = {
  ...sliceActions,
  ...routines,
}

export { appStateName, appReducer, appActions }
