import { completed, empty, failure, loading } from "@package/common/utils/state"
import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers"
import { createRoutine } from "redux-saga-routines"
import { AppUser } from "../../../../database/company/user/user.entity"
import { getSliceContextCreator } from "../../../state/context"
import {
  CalendarEntry,
  CalendarsState,
  SelectStoreCalendarInput,
} from "./types"
import { orderBy } from "lodash"

const watchCalendars = createRoutine("CALENDARS|WATCH_CALENDARS", {
  trigger: (payload: AppUser) => payload,
  success: (payload: CalendarEntry[]) => payload,
})

const initialState: CalendarsState = {
  calendars: empty(),
  selectedCalendarIds: [],
}

type CreateCalendarsSlice<T extends {} | undefined> = {
  extendState?: T
  extendExtraReducers?: (
    builder: ActionReducerMapBuilder<NoInfer<CalendarsState & T>>
  ) => void
}

const createCalendarsSlice = <T extends {}>(prop: CreateCalendarsSlice<T>) =>
  createSlice({
    name: "calendars",
    initialState: {
      ...initialState,
      ...prop.extendState,
    } as CalendarsState & T,
    reducers: {
      selectStoreCalendars(
        state,
        action: PayloadAction<SelectStoreCalendarInput>
      ) {
        state.selectedCalendarIds =
          state.calendars.data
            ?.filter(
              (x) =>
                x.definition.data.store &&
                action.payload.storeIds.includes(x.definition.data.store?.id)
            )
            ?.map((x) => x.definition.id) ?? []
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(watchCalendars.TRIGGER, (state) => {
          state.calendars = loading()
          state.selectedCalendarIds = []
        })
        .addCase(watchCalendars.FULFILL, (state) => {})
        .addCase(
          watchCalendars.SUCCESS,
          (state, action: ReturnType<typeof watchCalendars.success>) => {
            const sortedCalendars = orderBy(
              action.payload,
              (x) => x.definition?.data?.store?.name
            )
            state.calendars = completed(sortedCalendars)

            const firstCalendarStoreId =
              sortedCalendars.length > 0
                ? sortedCalendars[0].definition.data.store?.id
                : undefined

            state.selectedCalendarIds = firstCalendarStoreId
              ? sortedCalendars
                  .filter(
                    (x) => x.definition.data.store?.id === firstCalendarStoreId
                  )
                  .map((x) => x.definition.id)
              : sortedCalendars.length > 0
              ? [sortedCalendars[0].definition.id]
              : []
          }
        )
        .addCase(
          watchCalendars.FAILURE,
          (state, action: ReturnType<typeof watchCalendars.failure>) => {
            state.calendars = failure(action.payload)
          }
        )
      prop?.extendExtraReducers?.(builder as any)
    },
  })

const calendarRoutines = {
  watchCalendars,
}

export type CalendarSlice = ReturnType<typeof createCalendarsSlice>
export type CalendarRoutines = typeof calendarRoutines

export type CalendarSliceContext = CalendarSlice & {
  actions: CalendarRoutines
}

export const createCalendarSliceContext =
  getSliceContextCreator(calendarRoutines)

export { createCalendarsSlice, calendarRoutines }
