import React from "react"
import { ConfirmationContext } from "./context"
import { ConformationProvider } from "./provider"
import { UserConfirmationDefinition } from "./types"

export const useConfirmation = () => {
  const context = React.useContext(ConfirmationContext)

  const open = React.useCallback(
    (modal: UserConfirmationDefinition) => {
      if (!context) {
        throw new Error("Conformation provider not initialized")
      }
      context.openModal(modal)
    },
    [context]
  )

  return {
    withConfirmation: open,
  }
}

export { ConformationProvider }
