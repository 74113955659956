import {
  createPerformanceSliceContext,
  createPerformancesState,
  performanceRoutines,
} from "@package/common/frontend/features/performance/state"

export interface ExtraPerformanceState {}

export const performanceSlice = createPerformancesState<ExtraPerformanceState>(
  {}
)

const {
  name: performanceName,
  actions: sliceActions,
  reducer: performanceReducer,
} = performanceSlice

const performanceActions = {
  ...sliceActions,
  ...performanceRoutines,
}
export const performanceSliceContext =
  createPerformanceSliceContext(performanceSlice)
export type PerformanceSliceContext = typeof performanceSliceContext

export { performanceName, performanceReducer, performanceActions }
