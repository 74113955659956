import {
  createCalendarsSlice,
  calendarRoutines,
  createCalendarSliceContext,
} from "@package/common/frontend/features/calendar/state"

interface ExtraCalendarState {}

export const calendarSlice = createCalendarsSlice<ExtraCalendarState>({})
export default calendarSlice.reducer

export const calendarSliceContext = createCalendarSliceContext(calendarSlice)
export type CalendarSliceContext = typeof calendarSliceContext

export const calendarStateName = calendarSlice.name
export const calendarActions = {
  ...calendarRoutines,
  ...calendarSlice.actions,
}
