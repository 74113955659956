import { AppTheme } from "@package/common/features/themes/types"
import { createContext } from "react"
import { ThemeMode } from "./types"

export interface AppThemeContextData {
  theme?: AppTheme
  fallbackTheme?: AppTheme
  mode: ThemeMode
  setMode: (mode: ThemeMode) => void
}

export const AppThemeContext = createContext<AppThemeContextData>({
  mode: "light",
  setMode: () => {},
})
