import { CSSProperties } from "react"
import {
  ResponsiveObject,
  ResponsiveValue,
  ResposiveClassesOptions,
  ResposiveStyleOptions,
  Theme,
} from "./types"
export const themeKeys = ["light", "dark"] as const
export const breakpoints = ["", "_desk"]
export const defaultTheme: Theme = "light"

// const getValueWithPrefix = (prefix: string) => (val: string) =>
//   `var(--${prefix}-${val})`

export const getResponsiveStyle = <T, A extends string>(
  options: ResposiveStyleOptions<T, A>
) => {
  const values = Object.entries(options.values as any).reduce((o, [k, val]) => {
    ;(o[k] as any) = getResponsiveValues(val)!
    return o
  }, {} as Record<string, ResponsiveObject<T>[]>)
  if (values == null) {
    return {}
  }
  const style = breakpoints.reduce((o, _v, i) => {
    themeKeys.forEach((theme) => {
      let themeSuffix = ""
      if (theme !== defaultTheme) {
        themeSuffix = "--" + theme
      }
      const mappedValues = Object.entries(values).reduce((o, [k, val]) => {
        o[k] = val?.[i]?.[theme]
        return o
      }, {} as any)
      options.propertyNames.forEach((propName) => {
        const variableNamePrefix = `${propName}`
        const variableValue = options.mapFunction(propName, mappedValues)
        if (variableValue == null) {
          return
        }
        o[`--${variableNamePrefix}${breakpoints[i]}${themeSuffix}`] =
          variableValue
      })
    })

    return o
  }, {} as any)
  return style as CSSProperties
}
export const getResponsiveClasses = <T>(
  options: ResposiveClassesOptions<T>
) => {
  const values = Object.entries(options.values as any).reduce((o, [k, val]) => {
    ;(o[k] as any) = getResponsiveValues(val)!
    return o
  }, {} as Record<string, ResponsiveObject<T>[]>)
  if (values == null) {
    return []
  }
  const classes: string[] = []
  breakpoints.forEach((br, i) => {
    themeKeys.forEach((theme) => {
      const mappedValues = Object.entries(values).reduce((o, [k, val]) => {
        o[k] = val?.[i]?.[theme]
        return o
      }, {} as any)

      const themedVal = options.mapFunction(mappedValues)
      if (themedVal == null) {
        return
      }
      let themeSuffix = ""
      if (theme !== defaultTheme) {
        themeSuffix = "_" + theme
      }
      classes.push(`${themedVal}${br}${themeSuffix}`)
    })
  })
  return classes
}

export const getResponsiveValues = <T>(
  val: ResponsiveValue<T>
): ResponsiveObject<T>[] | null => {
  if (val == null) {
    return null
  }
  //transform to arrays
  let values: any[] = Array.isArray(val) ? val : [val]
  // transform to objects
  values = values.map((v: any) => {
    if (typeof v !== "object") {
      return { [defaultTheme]: v }
    }
    return v
  })
  return values
}

export const mapResponsiveValues = <T, Y>(
  val: ResponsiveValue<T>,
  func: (val: T) => Y
): ResponsiveValue<Y> => {
  const values = getResponsiveValues(val) as any
  return values?.map((x: any) => {
    return Object.entries(x).reduce((o: any, v: any) => {
      o[v[0]] = func(v[1])
      return o
    }, {} as ResponsiveObject<Y>)
  })
}
