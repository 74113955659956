import React from "react"
import { ConfirmationContext } from "./context"
import { ConfirmationModalAdapter } from "./modal"
import { UserConfirmationDefinition } from "./types"

export interface ConformationProviderProps {
  children: any
}

export const ConformationProvider = ({
  children,
}: ConformationProviderProps) => {
  const [modals, setModals] = React.useState<UserConfirmationDefinition[]>([])

  const openModal = (modal: UserConfirmationDefinition) =>
    setModals([...modals, modal])

  const closeModal = (id: string) =>
    setModals(modals.filter((m) => m.id !== id))

  const handleModalConfirm = (modal: UserConfirmationDefinition) => {
    closeModal(modal.id)
    modal.confirm.onClick?.()
  }

  const handleModalCancel = (modal: UserConfirmationDefinition) => {
    closeModal(modal.id)
    modal.cancel.onClick?.()
  }

  return (
    <ConfirmationContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      <>{children}</>
      <>
        {modals.map((modal) => (
          <ConfirmationModalAdapter
            key={modal.id}
            modal={modal}
            onConfirm={() => handleModalConfirm(modal)}
            onCancel={() => handleModalCancel(modal)}
          />
        ))}
      </>
    </ConfirmationContext.Provider>
  )
}
