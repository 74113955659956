import Typography from "@ui/atoms/Typography"
import CancelConfirmCta, { Cta, CtaAlign } from "@ui/molecules/CancelConfirmCta"
import Modal from "@ui/structure/Modal"
import React, { ReactNode } from "react"

export interface ConfirmationModalProps {
  open: boolean
  onClose?: () => void
  title?: ReactNode
  message?: ReactNode
  detail?: ReactNode
  cancel?: Cta
  confirm: Cta
  align?: CtaAlign
}

const ConfirmationModal = ({
  confirm,
  message,
  detail,
  cancel,
  open,
  title,
  onClose,
  align,
}: ConfirmationModalProps) => {
  return (
    <Modal title={title} open={open} onClose={onClose}>
      <Typography className="my-8">{message}</Typography>
      {detail}
      <CancelConfirmCta
        className="mt-8"
        align={align}
        confirm={confirm}
        cancel={cancel}
      />
    </Modal>
  )
}

ConfirmationModal.defaultProps = {
  align: "right",
}

export default ConfirmationModal
