import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { uiActions } from "../state"

export const useUiInitializer = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(uiActions.initializeTrigger())
  }, [])
}
