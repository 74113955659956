import { getSagaContext } from "@modules/store/redux/sagaContext"
import { call, put, takeLatest } from "typed-redux-saga"
import { appActions } from "."

function* appLoadSaga() {
  const firebase = yield* getSagaContext("firebase")
  try {
    yield* call(firebase.init)
    yield* put(appActions.setIsInitialized())
    yield* put(appActions.load.success())
  } catch (error) {
    yield* put(appActions.load.failure())
  } finally {
    yield* put(appActions.load.fulfill())
  }
}

export function* appRootSaga() {
  yield* takeLatest(appActions.load.TRIGGER, appLoadSaga)
}
