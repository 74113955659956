import { DashboardRootData } from "@package/common/api/functions/models/DashboardRootData"
import {
  completed,
  failure,
  LoadableState,
  loading,
} from "@package/common/utils/state"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface AppContextData {
  data: LoadableState<DashboardRootData, any>
}

const state = createSlice({
  name: "contextData",
  initialState: {
    data: loading(),
  } as AppContextData,
  reducers: {
    fetchDataTrigger(state) {
      state.data = loading()
    },
    fetchDataCompleted(state, action: PayloadAction<DashboardRootData>) {
      state.data = completed(action.payload)
    },
    fetchDataError(state) {
      state.data = failure()
    },
  },
})

export const rootContentActions = state.actions
export const rootContentsReducer = state.reducer
export const rootContentsName = state.name
