import React from "react"
import { ReactNode } from "react"
import { useDispatch } from "react-redux"
import { authActions } from "./redux"
import { useIsPublicPage } from "./hooks/useIsPublicPage"
import { useAppSelector } from "@modules/store/redux"
import LogRocket from "logrocket"
import { profileActions } from "@features/profile/state"
import { messagingActions } from "@features/messaging/state"
import { useRouter } from "next/router"
import { loginPagePath } from "@routing/paths"

type Props = {
  children: ReactNode
}

export const AuthProvider = (props: Props) => {
  const {
    isFirstCheckCompleted,
    authenticated,
    profile,
    uid,
    userType,
    isWatching,
  } = useAppSelector((state) => state.auth)
  const isPublicPage = useIsPublicPage()
  const dispatch = useDispatch()
  const router = useRouter()

  React.useEffect(() => {
    if (!isWatching) {
      dispatch(authActions.watch.trigger())
    }
    // return () => {
    //   console.log("Unmounting AuthProvider")
    //   dispatch(authActions.watch.fulfill())
    // }
  }, [])

  React.useEffect(() => {
    if (uid && userType) {
      dispatch(profileActions.watchUserProfile.trigger())
      dispatch(profileActions.watchUserResources.trigger())
      dispatch(
        messagingActions.watchUserKeys.trigger({
          uid,
        })
      )
    }
  }, [uid])

  React.useEffect(() => {
    if (uid) {
      LogRocket.identify(uid, {
        email: profile?.email ?? "",
        name: profile?.name ?? "",
      })
    }
  }, [uid])

  if (!isFirstCheckCompleted) {
    // todo: splash screen
    return <></>
  }

  if (!authenticated && !isPublicPage) {
    router.push(loginPagePath())
    // todo: splash screen
    return <></>
  }

  return <>{props.children}</>
}
