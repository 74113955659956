import React, { useEffect } from "react"

type Props = {
  children: React.ReactNode
}

const DeploymentsProvider = (props: Props) => {
  // const dispatch = useDispatch()
  useEffect(() => {
    // dispatch(
    //   deploymentsActions.watchCurrentDeployment.trigger({
    //     appId: process.env.REACT_APP_APP_ID ?? "",
    //     channelId: process.env.REACT_APP_CHANNEL_ID ?? "",
    //   })
    // )
    // return () => {
    //   dispatch(deploymentsActions.watchCurrentDeployment.fulfill())
    // }
  }, [])

  return <>{props.children}</>
}

export { DeploymentsProvider }
