import { useContext } from "react"
import { AppThemeContext } from "./context"

export const useAppTheme = () => {
  const { mode, fallbackTheme, theme } = useContext(AppThemeContext)

  if (!theme || !fallbackTheme) {
    throw new Error("App theme not found and no fallback theme was provided")
  }

  const palette = mode === "dark" ? theme.palette.dark : theme.palette.light

  return {
    theme: theme ?? fallbackTheme,
    mode,
    palette,
  }
}
