import {
  getUid,
  getFirebaseToken,
  login,
  logout,
  watchIsLoggedIn,
  getUser,
} from "./auth"

import { FirebaseApp, initializeApp } from "firebase/app"
import {
  CACHE_SIZE_UNLIMITED,
  Firestore,
  initializeFirestore,
  enableIndexedDbPersistence,
} from "firebase/firestore"
import { FirebaseStorage, getStorage } from "firebase/storage"
import {
  Auth,
  IdTokenResult,
  indexedDBLocalPersistence,
  initializeAuth,
  User,
} from "firebase/auth"
import { FirestoreCollections } from "@package/common/frontend/database/collections"

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}
class Firebase {
  protected app!: FirebaseApp
  protected firestore!: Firestore
  protected auth!: Auth

  protected token: string | null = null
  protected user: User | null = null
  protected tokenResult: IdTokenResult | null = null

  public storage!: FirebaseStorage
  public collections!: FirestoreCollections

  init = async () => {
    try {
      console.log("FIREBASE INIT", firebaseConfig)
      this.app = initializeApp(firebaseConfig)
      this.firestore = initializeFirestore(this.app, {
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
        ...(process.env.NEXT_PUBLIC_FIRESTORE_HOST === "" ||
        process.env.NEXT_PUBLIC_FIRESTORE_HOST == null
          ? {}
          : {
              host: process.env.NEXT_PUBLIC_FIRESTORE_HOST,
              ssl: false,
              experimentalForceLongPolling: true,
            }),
      })
      if (process.env.NEXT_PUBLIC_FIRESTORE_ENABLE_PERSISTENCE === "true") {
        await enableIndexedDbPersistence(this.firestore)
      }
      this.auth = initializeAuth(this.app, {
        persistence: indexedDBLocalPersistence,
      })
      this.storage = getStorage(this.app)
      this.collections = new FirestoreCollections(this.firestore)
    } catch (error) {
      console.log("INIT FIREBASE ERROR", error)
    }
  }

  getUid = getUid.bind(this)
  getFirebaseToken = getFirebaseToken.bind(this)
  login = login.bind(this)
  logout = logout.bind(this)
  watchIsLoggedIn = watchIsLoggedIn.bind(this)
  getUser = getUser.bind(this)
}

export default Firebase
