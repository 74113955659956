export const writeObject = <T>(key: string, value: T) =>
  window.localStorage.setItem(key, JSON.stringify(value))

export const readObject = <T>(key: string): T | undefined => {
  const serializedValue = window.localStorage.getItem(key)
  if (serializedValue === "undefined") {
    return undefined
  }
  return serializedValue ? (JSON.parse(serializedValue) as T) : undefined
}

export const removeObject = (key: string) => {
  window.localStorage.removeItem(key)
}

export const clearObjects = () => window.localStorage.clear()
