import { createRoutine } from "redux-saga-routines"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LoginInfo } from "@services/firebase/auth"
import { AuthState, UserData } from "./types"

export * from "./sagas"

const name = "auth"

const initialState: AuthState = {
  authenticated: false,
  loginError: false,
  userType: null,
  brandGroupCode: null,
  profile: null,
  isLoading: false,
  isWatching: false,
  isFirstCheckCompleted: false,
  uid: null,
}

const logout = createRoutine("LOGOUT")
const login = createRoutine("LOGIN", {
  trigger: (payload: LoginInfo) => payload,
})
const watch = createRoutine("WATCH")

const routines = {
  logout,
  login,
  watch,
}

const slice = createSlice({
  name,
  reducers: {
    loginSuccess(state, payloadAction: PayloadAction<UserData>) {
      state.authenticated = true
      state.loginError = false
      state.isLoading = false
      state.isFirstCheckCompleted = true
      state.userType = payloadAction.payload.userType
      //
      state.brandGroupCode = payloadAction.payload.brandGroupCode
      // getUserCustomAttributes(
      //   payloadAction.payload
      // )?.brandGroupCode
      state.uid = payloadAction.payload.uid
      state.profile = payloadAction.payload.profile
      // state.profile = {
      //   email: payloadAction.payload.email ?? "",
      //   name: payloadAction.payload.displayName ?? "",
      // }
    },
    setAnonymous(state) {
      state.authenticated = false
      state.loginError = false
      state.userType = null
      state.uid = null
      state.isLoading = false
      state.isFirstCheckCompleted = true
    },
  },
  initialState,
  extraReducers: (builder) => {
    //LOGIN
    builder
      .addCase(
        login.TRIGGER,
        (state, action: ReturnType<typeof login.trigger>) => {
          state.isLoading = true
          state.loginError = false
        }
      )
      .addCase(login.SUCCESS, (state) => {
        state.authenticated = true
        state.isLoading = false
        state.isFirstCheckCompleted = true
        state.loginError = false
      })
      .addCase(login.FAILURE, (state) => {
        state.authenticated = false
        state.isLoading = false
        state.isFirstCheckCompleted = true
        state.loginError = true
      })
    //LOGOUT
    builder.addCase(logout.SUCCESS, (state) => {
      state.authenticated = false
      state.userType = null
      state.isLoading = false
      state.isFirstCheckCompleted = true
      console.log("logout.SUCCESS", state)
    })
    //WATCH
    builder
      .addCase(watch.SUCCESS, (state) => {
        state.isWatching = true
      })
      .addCase(watch.FAILURE, (state) => {
        state.isWatching = false
      })
      .addCase(watch.FULFILL, (state) => {
        state.isWatching = false
      })
  },
})

const { name: authName, actions: sliceActions, reducer: authReducer } = slice

const authActions = {
  ...sliceActions,
  ...routines,
}

export { authName, authReducer, authActions }
