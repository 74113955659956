import { getSagaContext } from "@modules/store/redux/sagaContext"
import { User } from "firebase/auth"
import { buffers, eventChannel } from "redux-saga"
import { call, put, take, cancelled, takeLatest } from "typed-redux-saga"
import { authActions } from "."
import { extractUserData } from "./converters"

function* watchFirebaseAuthStateSaga() {
  const firebase = yield* getSagaContext("firebase")
  const firebaseAuthChannel = eventChannel<boolean>((emitter) => {
    const unsubscribe = firebase.watchIsLoggedIn((isLoggedIn) => {
      emitter(isLoggedIn)
    })
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(authActions.watch.FULFILL, function* () {
      firebaseAuthChannel.close()
    })
    yield* put(authActions.watch.success())

    while (true) {
      const isLoggedIn = yield* take(firebaseAuthChannel)
      if (!isLoggedIn) {
        yield* put(authActions.setAnonymous())
        // yield* put(authActions.logout.success())
      } else {
        yield* put(
          authActions.loginSuccess(extractUserData(firebase.getUser() as User))
        )
        // const keys = yield* call(api.personalKeysGet)
        // yield* put(messagingActions.setKeys(keys))
      }
    }
  } catch (error) {
    yield* put(authActions.watch.failure())
  } finally {
    if (yield* cancelled()) {
      firebaseAuthChannel.close()
    }
  }
}

function* logoutSaga() {
  const firebase = yield* getSagaContext("firebase")
  const routine = authActions.logout
  try {
    yield* call(firebase.logout)
    yield* put(routine.success())
  } catch (error) {
    yield* put(routine.failure())
  }
}

function* loginSaga({ payload }: ReturnType<typeof authActions.login.trigger>) {
  const firebase = yield* getSagaContext("firebase")

  const routine = authActions.login
  try {
    yield* call(firebase.login, payload)
    yield* put(
      authActions.loginSuccess(extractUserData(firebase.getUser() as User))
    )
  } catch (error) {
    yield* put(routine.failure())
  }
}

export function* authRootSaga() {
  yield* takeLatest(authActions.login.TRIGGER, loginSaga)
  yield* takeLatest(authActions.logout.TRIGGER, logoutSaga)
  yield* takeLatest(authActions.watch.TRIGGER, watchFirebaseAuthStateSaga)
}
