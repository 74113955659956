import { parse, stringify } from "query-string"

export const parseQuery = (value: string) => parse(value)

export const serializeQuery = (value: any) => stringify(value)

export const buildUrl = (url: string, params?: any) =>
  params ? `${url}?${serializeQuery(params)}` : url

export const getQueryParam = (name: string, location?: Location): string =>
  parse((location ?? window.location).search)[name] as string
