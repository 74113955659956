import { AuthProvider } from "@modules/auth"
import RootDataInitializer from "@modules/contents/components/RootDataLoader"
import { DeploymentsProvider } from "@modules/deployments/Provider"
import { ErrorsWrapper } from "@modules/errors"
import AppInitProvider from "@modules/initialization/components/AppInitProvider"
import { useLogRocket } from "@modules/tracking/hooks/useLogrocket"
import { useUiInitializer } from "@modules/ui/hooks/useUiInitializer"
import { useUiThemeHandler } from "@modules/ui/hooks/useUiThemeHandler"
import { ThemeProvider } from "@common/frontend/modules/theme"
import ApiProvider from "@root/api/ApiProvider"
import { ConformationProvider } from "@ui/notifications/confirmation"
import { ToastRoot } from "@ui/notifications/toast"
import { ThemeVariables } from "@ui/theme/variables"
import Head from "next/head"
import React from "react"

export interface AppRootProvidersProps {
  children: any
}

export const AppRootProviders = ({ children }: AppRootProvidersProps) => {
  useLogRocket()
  useUiInitializer()
  useUiThemeHandler()

  return (
    <ApiProvider>
      <ThemeProvider
        appTheme={ThemeVariables}
        fallbackTheme={ThemeVariables}
        Head={Head}
      >
        <AppInitProvider>
          <AuthProvider>
            <DeploymentsProvider>
              <ConformationProvider>
                <ErrorsWrapper>{children}</ErrorsWrapper>
                <ToastRoot />
              </ConformationProvider>
              <RootDataInitializer />
            </DeploymentsProvider>
          </AuthProvider>
        </AppInitProvider>
      </ThemeProvider>
    </ApiProvider>
  )
}
