import {
  createMessagingSlice,
  createMessagingSliceContext,
  messagingRoutines,
} from "@package/common/frontend/features/messaging/state/state"

interface ExtraMessagingState {}

export const messagingSlice = createMessagingSlice<ExtraMessagingState>({})
export default messagingSlice.reducer

export const messagingSliceContext = createMessagingSliceContext(messagingSlice)
export type MessagingSliceContext = typeof messagingSliceContext

export const messagingStateName = messagingSlice.name
export const messagingActions = {
  ...messagingSlice.actions,
  ...messagingRoutines,
}
