export const ensureTailingSlash = (value: string) =>
  value.endsWith("/") ? value : value + "/"

export const trimEnd = (value: string, char: string): string => {
  if (!value.endsWith(char)) {
    return value
  }

  return trimEnd(value.substring(0, value.length - 1), char)
}

export const trimStart = (value: string, char: string): string => {
  if (!value.startsWith(char)) {
    return value
  }

  return trimStart(value.substring(1), char)
}

export const trimChar = (value: string, char: string) =>
  trimStart(trimEnd(value, char), char)

export const toTitleCase = (string: string) => {
  const str = string.toLowerCase().split(" ")
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }

  return str.join(" ")
}

export const extractNumber = (string: string) => string.match(/\d+/g)?.[0]

export const isInt = (value: string | undefined) =>
  value?.match(/^\d+$/) ?? false

export const insensitiveEquals = (
  value1: string | undefined,
  value2: string | undefined
) => value1?.trim().toLocaleLowerCase() === value2?.trim().toLocaleLowerCase()

export const replaceAll = (
  input: string,
  value: string,
  replaceWith: string
) => {
  return input.split(value).join(replaceWith)
}

export const removeNewLines = (value: string) =>
  replaceAll(replaceAll(value, "\n", ""), "\r", "")
