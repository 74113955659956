import CustomButton, { ButtonSize } from "@ui/atoms/CustomButton"
import clsx from "clsx"
import React, { ReactNode } from "react"

export interface Cta {
  label: ReactNode
  onClick: () => void
  loading?: boolean
  disabled?: boolean
}

export type CtaAlign = "left" | "right"

export interface CancelConfirmCtaProps {
  cancel?: Cta
  confirm: Cta
  size?: ButtonSize
  className?: string
  align?: CtaAlign
}

const CancelConfirmCta = ({
  confirm,
  cancel,
  size,
  className,
  align,
}: CancelConfirmCtaProps) => {
  return (
    <div
      className={clsx(
        "flex gap-2",
        {
          "justify-start": align === "left",
          "justify-end": align === "right",
        },
        className
      )}
    >
      {cancel && (
        <CustomButton
          size={size}
          disabled={cancel.disabled}
          loading={cancel.loading}
          onClick={cancel.onClick}
          color="transparent"
        >
          {cancel.label}
        </CustomButton>
      )}
      {confirm && (
        <CustomButton
          size={size}
          disabled={confirm.disabled}
          loading={confirm.loading}
          onClick={confirm.onClick}
          color="primary"
        >
          {confirm.label}
        </CustomButton>
      )}
    </div>
  )
}

export default CancelConfirmCta
