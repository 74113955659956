import { getSagaContext } from "@modules/store/redux/sagaContext"
import { call, put } from "typed-redux-saga"
import { rootContentActions } from "../state"

export function* fetchContentsDataSaga() {
  const api = yield* getSagaContext("api")
  try {
    const data = yield* call(api.dashboardRootContents)
    yield* put(rootContentActions.fetchDataCompleted(data))
  } catch (e) {
    console.log("Fetch data error", e)
    yield* put(rootContentActions.fetchDataError())
  }
}
