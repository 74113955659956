import React from "react"
import { trimEnd } from "../../../utils/strings"
import { FunctionsOpenAPI } from "@package/common/api"
import { firebase } from "@services/common"

interface Props {
  children: any
}

FunctionsOpenAPI.TOKEN = async function () {
  return firebase.getFirebaseToken() ?? ""
}

const ApiProvider = ({ children }: Props) => {
  FunctionsOpenAPI.BASE = trimEnd(
    process.env.NEXT_PUBLIC_BACKEND_URL as string,
    "/"
  )

  return <>{children}</>
}

export default ApiProvider
